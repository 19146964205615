import BookIcon from '@/assets/img/mobile/book.png'
import ClueIcon from '@/assets/img/mobile/clue.png'
import DepositIcon from '@/assets/img/mobile/deposit.png'
import OrderIcon from '@/assets/img/mobile/order.png'
import OtherIcon from '@/assets/img/mobile/other.png'
import PrepaymentIcon from '@/assets/img/mobile/prepayment.png'
import ProductIcon from '@/assets/img/mobile/product.png'
import ReceptIcon from '@/assets/img/mobile/recept.png'
import ZxzsIcon from '@/assets/img/mobile/zxzs.png'
import Finance from '@/assets/img/mobile/finance.png'
import { getAuthMenuList } from '@/service/userService'
import { getOrderAppId } from '@/utils/prototypeAPI'
import { MobileRedirectRouteCenterEnmu } from '@/enmu/redirect'
import { hideWebViewTitle } from '@/utils/qingApi'
import { Grid, GridItem, NavBar, Tabbar, TabbarItem } from 'vant'
import { computed, defineComponent, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useUserStore } from '@/store/modules/user'
import { operOrder } from '@/utils/navigationSetting'
export default defineComponent({
    name: 'App',
    components: {
        [Grid.name]: Grid,
        [NavBar.name]: NavBar,
        [Tabbar.name]: Tabbar,
        [GridItem.name]: GridItem,
        [TabbarItem.name]: TabbarItem
    },
    beforeRouteEnter: (to:any, form:any) => {
    },
    setup () {
        hideWebViewTitle()
        const route = useRoute()
        const router = useRouter()
        const activeMenu = ref('/')
        const userData = computed(() => useUserStore().getUserInfo)
        const topMenu = [
            {
                serviceId: 'CRM',
                name: '线索录入',
                icon: ClueIcon,
                url: MobileRedirectRouteCenterEnmu.MSTUDENT
            },
            {
                serviceId: 'Order',
                name: '订单',
                icon: OrderIcon,
                url: MobileRedirectRouteCenterEnmu.Order + '/order/list'
            },
            {
                serviceId: 'Order',
                name: '收据',
                icon: ReceptIcon,
                url: MobileRedirectRouteCenterEnmu.Order + '/recept'
            },
            {
                serviceId: 'ZXZS',
                name: '咨询助手',
                icon: ZxzsIcon,
                url: MobileRedirectRouteCenterEnmu.ZXZS
            },
            {
                serviceId: 'CRM',
                name: '财务管理',
                icon: Finance,
                url: MobileRedirectRouteCenterEnmu.MSTUDENT + '/finance/approvalCenter'
            }
        ]
        const authMenu = ref<any>([])
        const handlerMenuClick = (event: any) => {
            if (event.url === '/morder/recept') {
                operOrder('receipt')
            } else if (event.url === '/morder/order/list') {
                operOrder('order')
            } else {
                if (event.url === '/mconsulthelper') {
                    localStorage.setItem('isRefresh', 'isRefresh')
                }
                router.push(event.url + '/')
            }
        }
        const handlerMenuChange = (url: string) => {
            router.push({ path: url })
        }
        const initMenuData = async () => {
            const result = await getAuthMenuList({ appId: 'orderMobile' })
            if (result) {
                topMenu.forEach(el => {
                    if (result.menuList.some((it:any) => it.href === el.url)) {
                        authMenu.value.push(el)
                    }
                })
            }
        }
        onMounted(() => {
            if ((localStorage.getItem('isRefresh'))) {
                localStorage.setItem('isRefresh', '')
                window.location.replace('/')
            }
        })
        initMenuData()
        return {
            authMenu,
            activeMenu,
            handlerMenuClick,
            handlerMenuChange
        }
    }
})
