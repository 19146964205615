/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// 关闭右上角弹出菜单
export const closePop = () => {
    window.qing.call('closePop')
}
// 隐藏页面标题
export const hideWebViewTitle = () => {
    window.qing.call('hideWebViewTitle')
}
// 设置页面标题并显示
export const setWebViewTitle = (title:string) => {
    window.qing.call('setWebViewTitle', { title: title })
}

// 自定义右上角弹出菜单
export const createPop = (operateList:CreatePopInterface, data?:any) => {
    window.qing.call('createPop', {
        popTitle: operateList.popTitle,
        items: operateList.items.map(item => ({
            text: item.text,
            callBackId: item.callBackId
        })),
        success: (res:any) => {
            if ((res.success === true || res.success === 'true') && res.data) {
                const callBackData = operateList.items.find(sitem => sitem.callBackId === res.data.callBackId)
                callBackData && callBackData.callback(data)
            }
        }
    })
}
// 自定义右上角弹出菜单
export const createBtnPop = (data:CreateBtnPopInterface) => {
    window.qing.call('createPop', {
        popTitle: data.popTitle,
        popTitleCallBackId: data.popTitleCallBackId,
        success: (res:any) => {
            console.log(res)
            if ((res.success === true || res.success === 'true') && res.data) {
                data && data.callback()
            }
        }
    })
}
